import React from 'react';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import { graphql } from 'gatsby';

import 'components/Blog/styles.css';
import { Layout } from 'components';
import BlogThumbnail from 'components/Blog/Thumbnail';
import VideoPlayer from 'components/VideoPlayer';

export default function Posts({ data: { allPosts, site, blog } }) {
  const { video, youtubeVideo } = blog;
  return (
    <Layout>
      <div className="container mx-auto">
        <HelmetDatoCms seo={blog.seo} favicon={site.favicon} />
        <h2 className="mb-2 mt-1 ml-1">Blog</h2>
        {(video?.video?.streamingUrl || youtubeVideo) && (
          <div className="container-flex flex-col">
            <VideoPlayer
              className="video-player-big"
              src={youtubeVideo || video?.video?.streamingUrl}
            />
          </div>
        )}
        <div className="srow">
          {allPosts.nodes.map((post, index) => (
            <BlogThumbnail key={index} post={post} />
          ))}
        </div>
      </div>
    </Layout>
  );
}

export const query = graphql`
  {
    site: datoCmsSite {
      favicon: faviconMetaTags {
        ...GatsbyDatoCmsFaviconMetaTags
      }
    }
    blog: datoCmsBlog {
      video {
        video {
          streamingUrl
        }
      }
      youtubeVideo
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    allPosts: allDatoCmsPost(sort: { fields: date, order: DESC }, limit: 20) {
      nodes {
        title
        slug
        excerpt
        date
        coverImage {
          #   large: gatsbyImageData(width: 1500)
          small: gatsbyImageData(width: 450, height: 213)
        }
        category: category2 {
          # 'category' name gives an error
          name
          originalId
          slug
        }
        author {
          name
          #   picture {
          #     gatsbyImageData(layout: FIXED, width: 48, height: 48, imgixParams: { sat: -100 })
          #   }
        }
      }
    }
  }
`;
